.App{
    min-height: 100vh;
}
::-webkit-scrollbar{
    width: 3px;
}
::-webkit-scrollbar-track{
    background: #e4e7eb;
}
::-webkit-scrollbar-thumb{
    background: #7d7d7d;
}
::-webkit-scrollbar-thumb:hover {
    background: #282828;
}
/* navbar */
.app-navbar{
    position: fixed;
    width: 100%;
	background: #fff;
    top: 0;
    z-index: 2;
}
.app-main{
    padding-top: 5rem;
    /* grid-template-rows: minmax(100vh, 1fr); */
}
  
button:focus,
input:focus,
textarea:focus{
    outline: 1px solid #ccc !important;
}

pre[class*="language-"] {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    overflow: auto;
}
code{
    overflow: auto !important;
    display: block !important;
    /* width: 100% !important; */
}

pre::-webkit-scrollbar{
    width: 4px !important;
}
code::-webkit-scrollbar, .preview-box::-webkit-scrollbar {
    height: 2px !important;
}
  
/* Track */
code::-webkit-scrollbar-track {
    background: #aefcf8;
  }
  
  /* Handle */
  code::-webkit-scrollbar-thumb {
    background: rgb(36, 150, 135);
  }
  
  /* Handle on hover */
  code::-webkit-scrollbar-thumb:hover {
    background: rgb(14, 99, 102);
  }

.logofont{
    /* font-family: 'Pacifico','Oxygen', 'Ubuntu', sans-serif; */
    font-family: 'Rubik Vinyl', cursive;
}
footer{
    position: relative;
}
footer .btn-to-top {
    position: absolute;
    right: 2rem;
    bottom: 0.75rem;
}
.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* large screens */
@media(min-width: 768px){
    .app-sidebar{
        /* display is set to none inside jsx file */
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        width: 225px;
        padding-top: 5rem;
        overflow-y: auto;
    }
    .app-sidebar::-webkit-scrollbar{
        width: 2px;        
    }
    .app-sidebar::-webkit-scrollbar-thumb{
        background: rgb(50, 103, 218);
    }

    .app-main{
        margin-left: 225px;
    }
    .app-index-toggle{
        display: none;
    }
    
    footer{
        z-index: 2;
    }
}


/* mobile */
@media(max-width: 767px){
    ::-webkit-scrollbar{
        width: 4px;
    }
    .app-code{
        /* prevents horizontal scroll due to code */
        /*width: calc(100vw - 3.4rem);*/
    }
    pre{
        max-height: 400px;
    }
    
    /* sidebar index */
    .app-sidebar{
        /* display is set to none inside jsx file */
        padding-top: 5rem;
        padding-bottom: 4rem;
        transition: all ease 0.35s;
        position: fixed;
        max-height: 100%;
        overflow-y: auto;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .app-index-toggle{
        position: fixed;
        z-index: 2;
        bottom: 1.5rem;
        right: 1.5rem;
    }
    .showOnMobile{
        display: block;
    }
    .hideOnMobile{
        display: none;
    }

    #navbar_example1, #navbar_example2, #navbar_example3{
        width: max-content;
    }

}
